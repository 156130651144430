/* eslint-disable no-unused-vars */
import React from 'react';

import Dropdown from './Dropdown';

import '../styles/DropdownFilter.scss';

export default function DropdownFilter({
  placeholder,
  tags,
  activeTag,
  onSelect,
  onClear,
}) {
  // Build options
  const options = [
    { value: -1, label: placeholder },
    ...(
      tags.map((tag) => ({
        value: tag.id,
        label: tag.attributes.name,
      }))
    ),
  ];

  // Event handlers
  const handleChange = (value) => {
    if (onClear && value === -1) {
      onClear();
    } else if (onSelect) {
      onSelect(tags.find((tag) => tag.id === value));
    }
  };

  // Render
  return (
    <div className="DropdownFilter">
      <Dropdown
        options={options}
        onChange={handleChange}
        selection={
          activeTag === null
            ? 0
            : options.findIndex((option) => option.value === activeTag.id)
        }
      />
    </div>
  );
}
