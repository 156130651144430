/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch } from 'react-redux';

import { getMenu } from '../data/paths';
import { closeFilters } from '../data/actions/navigate';
import '../styles/MenuBackground.scss';

export default function MenuBackground() {
  const dispatch = useDispatch();
  const menu = getMenu();

  // Event handlers
  const handleClick = () => {
    dispatch(closeFilters());
  };

  // Render
  return (
    <div className={`MenuBackground ${menu}`}>
      <button
        className="topSection"
        type="button"
        alt="Filter schliessen"
        onClick={handleClick}
      />
      <div className="bottomSection" />
    </div>
  );
}
