/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import RecipeBackground from '../components/RecipeBackground';
import RecipeHero from '../components/RecipeHero';
import RecipeHeader from '../components/RecipeHeader';
import RecipeIngredients from '../components/RecipeIngredients';
import RecipePreparation from '../components/RecipePreparation';
import AuthorTag from '../components/AuthorTag';

import dispatchRecipe from '../data/actions/dispatchRecipe';
import { useWakeLock } from '../hooks/useWakeLock';

import '../styles/Recipe.scss';

export default function Recipe() {
  const dispatch = useDispatch();
  const location = useLocation();

  // Don't let the screen sleep
  useWakeLock();

  // Fetch recipes on mount
  useEffect(() => {
    // Fetch recipes without any filters applied
    dispatch(dispatchRecipe(
      window.location.hash.split('/')[2],
      {},
    ));
  }, [location]);

  // Get the recipe data
  const activeRecipe = useSelector((state) => state.activeRecipe);

  useEffect(() => {
    // Display recipe as title
    if (activeRecipe.isSuccess && activeRecipe.data.attributes) {
      document.title = `${activeRecipe.data.attributes.name} - Sense of Taste`;
    }
  }, [activeRecipe]);

  // Try to get the recipe data
  const recipeAttributes = activeRecipe.isSuccess
    ? activeRecipe.data.attributes
    : null;

  // Render the menu
  return (
    <div className="Recipe">
      <RecipeBackground
        recipeName={recipeAttributes ? recipeAttributes.name : ''}
        image={recipeAttributes ? recipeAttributes.image.data : null}
      />
      <div className="scrollable">
        <RecipeHero
          recipeId={activeRecipe.isSuccess ? activeRecipe.data.id : ''}
          recipeName={recipeAttributes ? recipeAttributes.name : ''}
          recipeMenu={recipeAttributes ? recipeAttributes.recipe_menu : ''}
          image={recipeAttributes ? recipeAttributes.image.data : null}
        />
        <RecipeHeader
          label={recipeAttributes ? recipeAttributes.name : ''}
          recommendation={recipeAttributes ? recipeAttributes.recommendation : ''}
          rating={recipeAttributes ? recipeAttributes.rating : ''}
        />
        <div className="instructions">
          <RecipeIngredients
            ingredientLists={
              recipeAttributes ? recipeAttributes.ingredient_lists : []
            }
            relatedRecipes={
              recipeAttributes ? recipeAttributes.related_recipes.data : []
            }
          />
          <RecipePreparation
            preparationPhases={
              recipeAttributes ? recipeAttributes.preparation_phases : []
            }
          />
        </div>
        <AuthorTag
          author={
            recipeAttributes ? recipeAttributes.author : null
          }
        />
      </div>
    </div>
  );
}
