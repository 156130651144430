/* eslint-disable no-unused-vars */
import React from 'react';

import PlaceholderImage from './PlaceholderImage';
import paths from '../data/paths';
import '../styles/RecipeBackground.scss';

export default function RecipeBackground({ recipeName, image }) {
  return (
    <div className="RecipeBackground">
      {
        image
          ? (
            <img
              className="image"
              src={paths.API_ENDPOINT + image.attributes.formats.large.url}
              alt={recipeName}
            />
          )
          : (
            <PlaceholderImage
              className="placeholderImage"
            />
          )
      }
      <div className="bottomSection" />
    </div>
  );
}
