/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/Chip.scss';

export default function Chip({
  className,
  icon,
  onClick,
  href,
}) {
  const iconComponent = icon
    ? (
      <FontAwesomeIcon
        icon={icon}
        className="faIcon icon"
      />
    ) : null;

  // Action?
  if (onClick) {
    const handleClick = (e) => {
      // Try to trigger the callback
      if (onClick) {
        onClick(e);
      }
    };

    // Render the component
    return (
      <button
        className={`
          Chip
          ${className}
          `}
        type="button"
        onClick={handleClick}
      >
        {iconComponent}
      </button>
    );
  }

  // Link?
  if (href) {
    // Render the component
    return (
      <Link
        className={`
          Chip
          ${className}
          `}
        to={href}
      >
        {iconComponent}
      </Link>
    );
  }
}
