/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';

import IngredientList from './IngredientList';
import IngredientListButton from './IngredientListButton';

import '../styles/RecipeIngredients.scss';

library.add(
  faArrowUpRight,
);

export default function RecipeIngredients({ ingredientLists, relatedRecipes }) {
  const navigate = useNavigate();

  if (ingredientLists.length === 0) {
    return null;
  }
  return (
    <div className="RecipeIngredients">
      <div className="sectionTitle">
        <h3>Zutaten</h3>
      </div>
      {
        ingredientLists.length > 0 && ingredientLists.map((ingredientList) => (
          <IngredientList
            key={`ingredientList ${ingredientList.id}`}
            list={ingredientList}
          />
        ))
      }
      {
        relatedRecipes.length > 0 && relatedRecipes.map((relatedRecipe) => (
          <IngredientListButton
            key={`relatedRecipe ${relatedRecipe.id}`}
            icon={faArrowUpRight}
            label={relatedRecipe.attributes.name}
            onClick={() => { navigate(`/recipe/${relatedRecipe.id}`); }}
          />
        ))
      }
    </div>
  );
}
