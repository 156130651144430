/* eslint-disable no-unused-vars */
import React from 'react';
import '../styles/AuthorTag.scss';

export default function AuthorTag({ author }) {
  // No author, no tag.
  if (!author) {
    return null;
  }

  // Author has a URL, so make the tag a link.
  if (author.url) {
    return (
      <div className="AuthorTag url">
        <a
          href={author.url}
          target="_blank"
          rel="noreferrer"
        >
          <span>
            {`von ${author.name}`}
          </span>
        </a>
      </div>
    );
  }

  // Author has no URL, so just display the name.
  return (
    <div className="AuthorTag">
      <div>
        <span>
          {`von ${author.name}`}
        </span>
      </div>
    </div>
  );
}
