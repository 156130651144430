/* eslint-disable no-unused-vars */
import React from 'react';
import '../styles/PreparationPhase.scss';

export default function PreparationPhase({ phase }) {
  return (
    <div className="PreparationPhase">
      <div className="header">
        <span>{phase.identifier}</span>
        <span>{phase.name}</span>
      </div>
      <div className="steps">
        {
          phase.steps.split('\n').map((step, index) => (
            <p
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {step}
            </p>
          ))
        }
      </div>
    </div>
  );
}
