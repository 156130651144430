import { combineReducers } from 'redux';

import navigationReducer from './navigationReducer';
import activeMenuReducer from './activeMenuReducer';
import filterReducer from './filterReducer';
import recipeListReducer from './recipeListReducer';

import activeRecipeReducer from './activeRecipeReducer';

const allReducer = combineReducers({
  navigation: navigationReducer,
  activeMenu: activeMenuReducer,
  appliedFilters: filterReducer,
  recipes: recipeListReducer,
  activeRecipe: activeRecipeReducer,
});
export default allReducer;
