export function resetFilter(menu) {
  return ({
    type: 'RESET_FILTER',
    payload: { menu },
  });
}

export function toggleCategoryFilter(category) {
  return ({
    type: 'TOGGLE_CATEGORY_FILTER',
    payload: { category },
  });
}

export function toggleIngredientFilter(ingredient) {
  return ({
    type: 'TOGGLE_INGREDIENT_FILTER',
    payload: { ingredient },
  });
}

export function setOnlyAvailable(onlyAvailable) {
  return ({
    type: 'SET_ONLY_AVAILABLE',
    payload: { onlyAvailable },
  });
}

export function setOriginFilter(origin) {
  return ({
    type: 'SET_ORIGIN_FILTER',
    payload: { origin },
  });
}

export function clearOriginFilter() {
  return ({
    type: 'SET_ORIGIN_FILTER',
    payload: { origin: null },
  });
}

export function setSearch(search) {
  return ({
    type: 'SET_SEARCH',
    payload: { search },
  });
}

export function clearSearch() {
  return ({
    type: 'SET_SEARCH',
    payload: { search: '' },
  });
}
