/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilterHeader from './FilterHeader';
import FilterSection from './FilterSection';
import ChipFilter from './ChipFilter';
import ListFilter from './ListFilter';
import DropdownFilter from './DropdownFilter';
import LimitedIngredientPanel from './LimitedIngredientPanel';

import {
  toggleCategoryFilter,
  toggleIngredientFilter,
  setOriginFilter,
  clearOriginFilter,
} from '../data/actions/filter';
import { getMenu } from '../data/paths';

import '../styles/FilterPanel.scss';

// sorting
const sortIngredients = (ingredients) => {
  const availabilityOrder = ['', 'preorder', 'special order'];

  return ingredients.sort((a, b) => {
    // An ingredient beginning with 'weitere' always comes last
    if (a.attributes.name.startsWith('weitere')) return 1;
    if (b.attributes.name.startsWith('weitere')) return -1;

    // Sort by availability and then by name
    if (
      availabilityOrder.indexOf(a.attributes.availability)
      === availabilityOrder.indexOf(b.attributes.availability)
    ) {
      return a.attributes.name.localeCompare(b.attributes.name);
    }
    return availabilityOrder.indexOf(a.attributes.availability)
      - availabilityOrder.indexOf(b.attributes.availability);
  });
};

export default function FilterPanel() {
  const dispatch = useDispatch();

  // Track the opened filter tab
  const [openTab, setOpenTab] = React.useState(1);
  const menu = getMenu();
  const activeMenu = useSelector((state) => state.activeMenu);
  const appliedFilters = useSelector((state) => state.appliedFilters);

  // Build filter sections
  let filterSections = null;
  if (activeMenu.isSuccess) {
    switch (openTab) {
      case 1: {
        switch (menu) {
          case 'kitchen': {
            filterSections = (
              <FilterSection>
                <ChipFilter
                  tags={
                        activeMenu.data.attributes.recipe_categories.data
                          .sort((a, b) => a.attributes.order - b.attributes.order)
                      }
                  activeTags={appliedFilters.categories}
                  onToggle={(tag) => { dispatch(toggleCategoryFilter(tag)); }}
                />
              </FilterSection>
            );
            break;
          }
          case 'bar':
          default: {
            // Gather all category_groups
            const recipeGroups = activeMenu.data.attributes.recipe_categories.data
              .reduce((accumulator, currentValue) => {
                // console.log(currentValue.attributes.category_group);
                if (currentValue.attributes.category_group) {
                  const group = currentValue.attributes.category_group.data;
                  if (!accumulator.find((item) => item.id === group.id)) {
                    accumulator.push(group);
                  }
                }
                return accumulator;
              }, [])
              .sort((a, b) => a.attributes.order - b.attributes.order);
            console.log(recipeGroups);
            // Build filter sections
            filterSections = (
              <>
                {
                  recipeGroups.map((group) => (
                    <FilterSection
                      key={group.id}
                      label={group.attributes.name}
                    >
                      <ChipFilter
                        tags={
                          activeMenu.data.attributes.recipe_categories.data
                            .filter((category) => (
                              category.attributes.category_group.data.id === group.id))
                            .sort((a, b) => a.attributes.order - b.attributes.order)
                        }
                        activeTags={appliedFilters.categories}
                        onToggle={(tag) => { dispatch(toggleCategoryFilter(tag)); }}
                      />
                    </FilterSection>
                  ))
                }
              </>
            );
          }
        }
        break;
      }
      case 2: {
        // ingredient_categories - multiple chip filters
        filterSections = (
          <>
            {
              menu === 'bar'
                ? (
                  <LimitedIngredientPanel />
                )
                : null
            }
            {
              activeMenu.data.attributes.ingredient_categories.data
                .sort((a, b) => a.attributes.order - b.attributes.order)
                .map((category) => (
                  <FilterSection
                    key={category.id}
                    label={category.attributes.name}
                  >
                    <ChipFilter
                      tags={sortIngredients(category.attributes.ingredients.data)}
                      activeTags={appliedFilters.ingredients}
                      onToggle={(tag) => { dispatch(toggleIngredientFilter(tag)); }}
                    />
                  </FilterSection>
                ))
            }
          </>
        );
        break;
      }
      case 3: {
        // recipe_origin - dropdown
        filterSections = (
          <FilterSection>
            <DropdownFilter
              tags={
                activeMenu.data.attributes.recipe_origins.data
                  // sort by order and then by name
                  .sort((a, b) => {
                    if (a.attributes.order === b.attributes.order) {
                      return a.attributes.name.localeCompare(b.attributes.name);
                    }
                    return a.attributes.order - b.attributes.order;
                  })
              }
              activeTag={appliedFilters.origin}
              onSelect={(tag) => { dispatch(setOriginFilter(tag)); }}
              onClear={() => { dispatch(clearOriginFilter()); }}
              placeholder="Ursprung wählen"
            />
          </FilterSection>
        );
        break;
      }
      default: {
        filterSections = null;
      }
    }
  }

  return (
    <div className="FilterPanel">
      <FilterHeader
        appliedFilters={appliedFilters}
        openTab={openTab}
        setOpenTab={setOpenTab}
      />
      {
        filterSections
      }
    </div>
  );
}
