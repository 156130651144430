/* eslint-disable no-unused-vars */
import React from 'react';
import '../styles/FilterSection.scss';

export default function FilterSection({
  label,
  children,
}) {
  return (
    <div className="FilterSection">
      {
        label && (
          <h6 className="label">{label}</h6>
        )
      }
      <div className="content">
        {children}
      </div>
    </div>
  );
}
