import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MenuBackground from '../components/MenuBackground';
import RecipeList from '../components/RecipeList';
import FilterPanel from '../components/FilterPanel';
import SearchPanel from '../components/SearchPanel';
import ChipMenu from '../components/ChipMenu';
import FilterResultCounter from '../components/FilterResultCounter';
import CreateRecipeChip from '../components/CreateRecipeChip';

import dispatchMenu from '../data/actions/dispatchMenu';
import { getMenu } from '../data/paths';
import { useWakeUnlock } from '../hooks/useWakeLock';

import '../styles/Menu.scss';
import { resetFilter } from '../data/actions/filter';

export default function Menu() {
  // Track whether the filter is open or closed
  const dispatch = useDispatch();
  const navigation = useSelector((state) => state.navigation);
  const location = useLocation();

  // Let the screen sleep
  useWakeUnlock();

  // Fetch menu data when the page loads
  useEffect(() => {
    const menuName = getMenu();
    dispatch(dispatchMenu(menuName));
    dispatch(resetFilter(menuName));

    // Reset tab title
    document.title = 'Sense of Taste';
  }, [location.pathname]);

  // Render the menu
  return (
    <div className="Menu">
      <MenuBackground />
      <div className="scrollable">
        <div className="topSection">
          <div className="logo" />
          <ChipMenu />
        </div>
        {
          navigation.activeFilterPanel === 'filter'
            ? <FilterPanel />
            : null
        }
        {
          navigation.activeFilterPanel === 'search'
            ? <SearchPanel />
            : null
        }
        {
          navigation.activeFilterPanel === 'filter'
          || navigation.activeFilterPanel === 'search'
            ? <FilterResultCounter />
            : <CreateRecipeChip />
        }
        <RecipeList />
      </div>
    </div>
  );
}
