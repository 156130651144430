/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import paths from '../paths';

export default function dispatchFiltering(menu, filters) {
  // Build path
  let getterPath = `${paths.API_ENDPOINT}/api/recipes`
    + '?populate[0]=image'
    + '&pagination[limit]=-1'
    + `&filters[$and][0][recipe_menu][$eq]=${menu}`;

  // Add filters to path
  let filterIndex = 1;
  filters.categories.forEach((category) => {
    getterPath += `&filters[$and][${filterIndex}][recipe_categories][id][$eq]=${category.id}`;
    filterIndex += 1;
  });
  filters.ingredients.forEach((ingredient) => {
    getterPath += `&filters[$and][${filterIndex}][ingredients][id][$eq]=${ingredient.id}`;
    filterIndex += 1;
  });
  if (filters.origin) {
    getterPath += `&filters[$and][${filterIndex}][recipe_origins][id][$eq]=${filters.origin.id}`;
    filterIndex += 1;
  }
  if (filters.search !== '') {
    getterPath += `&filters[$and][${filterIndex}][name][$containsi]=${filters.search}`;
    filterIndex += 1;
  }
  if (filters.onlyAvailable) {
    getterPath += `&filters[$and][${filterIndex}][always_available]=true`;
    filterIndex += 1;
  }

  // Sort by edited date
  getterPath += '&sort=updatedAt:desc';

  return ({
    type: 'FETCH_RECIPE_LIST',
    payload: axios.get(getterPath),
  });
}
