/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';

import '../styles/Dropdown.scss';

library.add(
  faChevronDown,
);

const DropdownIndicator = (props) => (
  components.DropdownIndicator && (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon
      icon={faChevronDown}
      className="icon"
    />
  </components.DropdownIndicator>
  )
);

export default function Dropdown({
  options = [{ value: '0', label: 'empty' }],
  selection = 0,
  onChange,
  onState,
}) {
  const [dropdownSelection, selectDropdown] = useState(options[selection]);
  if (!dropdownSelection) {
    // eslint-disable-next-line no-console
    console.log({ error: 'Option/Selcetion invalid!', options, selection });
    return null;
  }

  const handleChange = (selectedOption) => {
    selectDropdown(selectedOption);
    if (onChange) {
      onChange(parseInt(selectedOption.value, 10));
    }
  };
  const handleOpen = () => {
    if (onState) {
      onState('OPEN');
    }
  };
  const handleClose = () => {
    if (onState) {
      onState('CLOSE');
    }
  };

  useEffect(() => {
    if (selection !== null && dropdownSelection.value !== `${selection}`
    ) {
      selectDropdown(options[selection]);
    }
  }, [selection]);

  return (
    <Select
      className="Select Dropdown"
      classNamePrefix="taste-select"
      components={{ DropdownIndicator }}
      value={dropdownSelection}
      options={options}
      onChange={handleChange}
      onMenuOpen={handleOpen}
      onMenuClose={handleClose}
    />
  );
}
