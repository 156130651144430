/* eslint-disable no-unused-vars */
import React from 'react';
import '../styles/FilterResultCounter.scss';
import { useSelector } from 'react-redux';

export default function FilterResultCounter() {
  const recipes = useSelector((state) => state.recipes);
  return (
    <div className="FilterResultCounter">
      <span>
        {
          recipes.isSuccess && recipes.data.length > 0
            ? `${recipes.data.length} Rezepte`
            : 'keine Rezepte gefunden'
        }
      </span>
    </div>
  );
}
