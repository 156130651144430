export default function navigateToFilter(filterName) {
  return ({
    type: 'NAVIGATE_TO_FILTER',
    payload: { filterName },
  });
}
export function closeFilters() {
  return ({
    type: 'CLOSE_FILTERS',
  });
}
