/* eslint-disable no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/IngredientListButton.scss';

export default function IngredientListButton({ onClick, icon, label }) {
  return (
    <button
      className="IngredientListButton closed"
      onClick={onClick ? () => onClick() : null}
      type="button"
    >
      <FontAwesomeIcon
        icon={icon}
        className="faIcon icon"
      />
      <span className="label">{label}</span>
    </button>
  );
}
