import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './data/reducers/store';

import './index.css';

// Remove service worker
console.log('Welcome to sense of taste!');
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    console.log('Unregistering service worker...');
    registration.unregister();

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      console.log('Clearing service worker cache...');
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
  });
}

// Render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
);
