/* eslint-disable default-param-last */
import baseFetchReducer, { getDefaultFetchState } from './baseFetch';

const activeRecipeReducer = (
  state = getDefaultFetchState(),
  action,
) => baseFetchReducer(
  'ACTIVE_RECIPE',
  state,
  action,
);
export default activeRecipeReducer;
