import axios from 'axios';
import paths from '../paths';

export default function dispatchRecipe(recipeId) {
  // Build path
  const getterPath = `${paths.API_ENDPOINT}/api/recipes/${recipeId}`
    + '?populate[0]=image'
    + '&populate[1]=ingredient_lists.ingredients'
    + '&populate[2]=related_recipes'
    + '&populate[3]=preparation_phases'
    + '&populate[4]=author';

  return ({
    type: 'FETCH_ACTIVE_RECIPE',
    payload: axios.get(getterPath),
  });
}
