/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChipFilter from './ChipFilter';

import { setOnlyAvailable } from '../data/actions/filter';
import '../styles/LimitedIngredientPanel.scss';

export default function LimitedIngredientPanel() {
  const { onlyAvailable } = useSelector((state) => state.appliedFilters);
  const dispatch = useDispatch();

  // Event handlers
  const handleToggle = (tag) => {
    dispatch(setOnlyAvailable(tag.id === 'available'));
  };

  const tags = [
    {
      id: 'available',
      attributes: {
        name: 'Immer verfügbare Drinks',
      },
    },
    {
      id: 'unavailable',
      attributes: {
        name: 'Alle Drinks',
      },
    },
  ];

  // Render
  return (
    <div className="LimitedIngredientPanel">
      <span>Lust auf einen bestimmten Drink?</span>
      <span>
        Zutaten einem Stern (*) können gerne besorgt werden,
        Zutaten mit zwei Sternen (**) sind etwas Komplizierter. Am besten einfach fragen.
      </span>
      <div className="toggle">
        <ChipFilter
          tags={tags}
          activeTags={onlyAvailable ? [tags[0]] : [tags[1]]}
          onToggle={handleToggle}
        />
      </div>
    </div>
  );
}
