/* eslint-disable no-unused-vars */
import React from 'react';
import {
  faStar,
} from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/RecipeHeader.scss';

library.add(
  faStar,
);

export default function RecipeHeader({
  label,
  recommendation,
  rating,
}) {
  // Star rating
  let starRating;
  const star = (
    <FontAwesomeIcon
      className="icon faIcon star"
      icon={faStar}
    />
  );
  switch (rating) {
    case 'average': {
      starRating = (
        <>
          {star}
          {star}
          {star}
        </>
      );
      break;
    }
    case 'good': {
      starRating = (
        <>
          {star}
          {star}
          {star}
          {star}
        </>
      );
      break;
    }
    case 'amazing': {
      starRating = (
        <>
          {star}
          {star}
          {star}
          {star}
          {star}
        </>
      );
      break;
    }
    default: {
      break;
    }
  }

  // Render
  return (
    <div className="RecipeHeader">
      <h1>{label || '...'}</h1>
      {
        recommendation && (
          <span className="recommendation">
            {recommendation}
          </span>
        )
      }
      {
        starRating && (
          <div className="starRating">
            {starRating}
          </div>
        )
      }
    </div>
  );
}
