/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';

import Ingredient from './Ingredient';
import IngredientListButton from './IngredientListButton';

import '../styles/IngredientList.scss';

library.add(
  faPlus,
);

export default function IngredientList({ list }) {
  const [isOpen, setIsOpen] = useState(true);

  // Support close button
  if (!isOpen) {
    return (
      <IngredientListButton
        icon={faPlus}
        label={list.name}
        onClick={() => setIsOpen(!isOpen)}
      />
    );
  }

  // Render
  return (
    <button
      className="IngredientList open"
      onClick={() => setIsOpen(!isOpen)}
      type="button"
    >
      <Ingredient
        isTitle
        quantity={list.quantity}
        unit={list.unit}
        name={list.name}
      />
      {
        list.ingredients.map((ingredient) => (
          <Ingredient
            key={ingredient.id}
            quantity={ingredient.quantity}
            unit={ingredient.unit}
            name={ingredient.name}
          />
        ))
      }
    </button>
  );
}
