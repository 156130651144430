import axios from 'axios';
import paths from '../paths';

export default function dispatchRecipe(menu) {
  let menuId;
  switch (menu) {
    case paths.MENU_BAR: {
      menuId = paths.MENU_BAR_ID;
      break;
    }
    case paths.MENU_KITCHEN:
    default: {
      menuId = paths.MENU_KITCHEN_ID;
      break;
    }
  }

  // Build path
  const getterPath = `${paths.API_ENDPOINT}/api/menus/${menuId}`
    + '?populate[0]=recipe_categories'
    + '&populate[1]=recipe_categories.category_group'
    + '&populate[2]=ingredient_categories'
    + '&populate[3]=ingredient_categories.ingredients'
    + '&populate[4]=recipe_origins';

  return ({
    type: 'FETCH_ACTIVE_MENU',
    payload: axios.get(getterPath),
  });
}
