/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';

import PlaceholderImage from './PlaceholderImage';
import paths from '../data/paths';
import '../styles/RecipeCard.scss';

export default function RecipeCard({ recipe }) {
  // Placeholder?
  if (!recipe) {
    return (
      <div className="RecipeCard placeholder">
        <PlaceholderImage className="image" />
        <div className="label">
          <div />
        </div>
      </div>
    );
  }

  // Render the recipe card
  return (
    <Link
      className="RecipeCard"
      to={`/recipe/${recipe.id}`}
    >
      {
        recipe.attributes.image.data
          ? (
            <div
              className="image url"
              style={{
                backgroundImage: `url(${paths.API_ENDPOINT + recipe.attributes.image.data.attributes.formats.thumbnail.url})`,
              }}
            />
          ) : (<PlaceholderImage className="image" />)
      }
      <div className="label">
        <span>
          {recipe.attributes.name}
        </span>
      </div>
    </Link>
  );
}
