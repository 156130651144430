/* eslint-disable no-unused-vars */
import React from 'react';

import { getMenu } from '../data/paths';
import '../styles/ChipFilter.scss';

export default function ChipFilter({
  tags,
  activeTags,
  onToggle,
}) {
  const menu = getMenu();

  // Availability tag
  const getActiveTag = (tag) => {
    if (menu === 'kitchen') return '';

    switch (tag.attributes.availability) {
      case 'special order':
        return '**';
      case 'preorder':
        return '*';
      default:
        return '';
    }
  };

  // Render
  return (
    <div className="ChipFilter">
      {
        tags.map((tag) => (
          <button
            key={tag.id}
            className={`chip ${
              activeTags.filter((activeTag) => activeTag.id === tag.id).length > 0
                ? 'active' : ''}`}
            type="button"
            onClick={() => { onToggle(tag); }}
          >
            <span>
              {
                tag.attributes.name + getActiveTag(tag)
              }
            </span>
          </button>
        ))
      }
    </div>
  );
}
