/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import '../styles/PlaceholderImage.scss';

const placeholderColorSets = [
  ['F5F5F4', 'E2E2E2'],
  ['F9F9F8', 'EAEAEA'],
  ['E3E3DF', 'D6D6D5'],
  ['E7E6E2', 'D5D4D0'], // edited in code
  // ['C7C6C2', 'C5C4C0'], // too dark and bland
  ['F9F9F9', 'ECECE9'],
  ['DCDAD6', 'C6C4C1'],
  // ['FFFFFF', '000000'], // debugging
];

export default function PlaceholderImage({ className }) {
  // Select random color
  const randomPlaceholderColor = useRef(
    placeholderColorSets[
      Math.floor(Math.random() * placeholderColorSets.length)
    ],
  );

  return (
    <div
      className={`PlaceholderImage ${className}`}
      style={{
        background: `radial-gradient(57.08% 57.08% at 50% 29.58%, #${
          randomPlaceholderColor.current[0]
        } 0%, #${
          randomPlaceholderColor.current[1]
        } 100%)`,
      }}
    />
  );
}
