/* eslint-disable no-unused-vars */
import React from 'react';

import PreparationPhase from './PreparationPhase';
import '../styles/RecipePreparation.scss';

export default function RecipePreparation({ preparationPhases }) {
  return (
    <div className="RecipePreparation">
      <div className="sectionTitle">
        <h3>Zubereitung</h3>
      </div>
      {
        preparationPhases.map((preparationPhase) => (
          <PreparationPhase
            key={preparationPhase.id}
            phase={preparationPhase}
          />
        ))
      }
    </div>
  );
}
