/* eslint-disable default-param-last */

const navigationReducer = (
  state = {
    activeFilterPanel: 'closed',
  },
  action,
) => {
  const newState = state;
  switch (action.type) {
    case 'NAVIGATE_TO_FILTER': {
      return {
        ...newState,
        activeFilterPanel: action.payload.filterName,
      };
    }
    case 'CLOSE_FILTERS': {
      return {
        ...newState,
        activeFilterPanel: 'closed',
      };
    }
    default: {
      return state;
    }
  }
};
export default navigationReducer;
