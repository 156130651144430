import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import RecipeCard from './RecipeCard';

import dispatchFiltering from '../data/actions/dispatchFiltering';
import { getMenu } from '../data/paths';

import '../styles/RecipeList.scss';

export default function RecipeList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const recipes = useSelector((state) => state.recipes);
  const appliedFilters = useSelector((state) => state.appliedFilters);

  // Load recipes with applied filters
  useEffect(() => {
    const menu = getMenu();
    dispatch(dispatchFiltering(menu, appliedFilters));
  }, [appliedFilters, location.pathname]);

  // List recipes for dev purposes
  // console.log(
  //   recipes.data.reduce(
  //     (accumulator, currentValue) => `${accumulator}, ${currentValue.attributes.name}`,
  //     '',
  //   ),
  // );

  // Render the recipe list
  return (
    <div className="RecipeList">
      <div className="backgroundBlur" />
      {
        recipes.isSuccess && recipes.data.length > 0
          ? recipes.data.map((recipe) => (
            <RecipeCard
              key={recipe.id}
              recipe={recipe}
            />
          ))
          : Array(16).fill(null).map((recipe, index) => (
            <RecipeCard
            // eslint-disable-next-line react/no-array-index-key
              key={index}
              recipe={recipe}
            />
          ))
      }
    </div>
  );
}
