/* eslint-disable no-unused-vars */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Menu from './routes/Menu';
import Recipe from './routes/Recipe';
import paths from './data/paths';

import './styles/App.scss';

const App = () => (
  <div>
    <Routes>
      <Route
        exact
        path="/"
        element={<Navigate to={`/${paths.MENU_KITCHEN}`} />}
      />
      <Route
        exact
        path={`/${paths.MENU_KITCHEN}`}
        element={<Menu />}
      />
      <Route
        exact
        path={`/${paths.MENU_BAR}`}
        element={<Menu />}
      />
      <Route
        path="/recipe/:recipeId"
        element={<Recipe />}
      />
    </Routes>
  </div>
);
export default App;
