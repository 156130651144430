/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';

import {
  resetFilter,
} from '../data/actions/filter';

import '../styles/FilterHeader.scss';

library.add(
  faXmark,
);

export default function FilterHeader({
  openTab,
  setOpenTab,
  appliedFilters,
}) {
  const dispatch = useDispatch();

  const handleClick = (tab) => () => {
    setOpenTab(tab);
  };

  return (
    <div className="FilterHeader">
      <button
        className={`filterTab category ${openTab === 1 ? 'active' : ''}`}
        type="button"
        onClick={handleClick(1)}
      >
        <p>
          Kategorie
          {
            appliedFilters.categories.length > 0
            && (
              <>
                &nbsp;
                <sup>{appliedFilters.categories.length}</sup>
              </>
            )
          }
        </p>
      </button>
      <button
        className={`filterTab ingredients ${openTab === 2 ? 'active' : ''}`}
        type="button"
        onClick={handleClick(2)}
      >
        <p>
          Zutaten
          {
            (
              appliedFilters.ingredients.length > 0
              || appliedFilters.onlyAvailable
            )
            && (
              <>
                &nbsp;
                <sup>
                  {
                    appliedFilters.ingredients.length
                    + (appliedFilters.onlyAvailable ? 1 : 0)
                  }
                </sup>
              </>
            )
          }
        </p>
      </button>
      <button
        className={`filterTab origin ${openTab === 3 ? 'active' : ''}`}
        type="button"
        onClick={handleClick(3)}
      >
        <p>
          Ursprung
          {
            appliedFilters.origin !== null
            && (
              <>
                &nbsp;
                <sup>1</sup>
              </>
            )
          }
        </p>
      </button>
      {
        (
          appliedFilters.categories.length > 0
          || appliedFilters.ingredients.length > 0
          || appliedFilters.onlyAvailable
          || appliedFilters.origin !== null
        )
        && (
          <button
            className="filterTab reset"
            type="button"
            alt="Filter zurücksetzen"
            onClick={() => dispatch(resetFilter())}
          >
            <FontAwesomeIcon
              icon={faXmark}
              className="faIcon icon"
            />
          </button>
        )
      }
    </div>
  );
}
