// const baseAPI = 'http://localhost:1337';
const baseAPI = 'https://edit-taste.pillars.ch';

const collectionAPI = `${baseAPI}/admin/content-manager/collectionType/api::`;

const paths = {
  API_ENDPOINT: baseAPI,
  ADMIN_CREATE_RECIPE: `${collectionAPI}recipe.recipe/create`,
  MENU_KITCHEN: 'kitchen',
  MENU_BAR: 'bar',

  // // development
  // MENU_KITCHEN_ID: '1',
  // MENU_BAR_ID: '2',

  // production
  MENU_KITCHEN_ID: '1',
  MENU_BAR_ID: '2',
};
export default paths;

export function getMenu() {
  return window.location.hash.split('/')[1];
}

export function getMenuPath(menu) {
  switch (menu) {
    case paths.MENU_BAR: {
      return `${process.env.PUBLIC_URL}/${paths.MENU_BAR}`;
    }
    case paths.MENU_KITCHEN: {
      return `${process.env.PUBLIC_URL}/${paths.MENU_KITCHEN}`;
    }
    default: {
      return '';
    }
  }
}

export function getEditRecipePath(recipeId) {
  return `${collectionAPI}recipe.recipe/${recipeId}`;
}
