/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faXmark,
} from '@fortawesome/pro-light-svg-icons';

import {
  setSearch as dispatchSearch,
  clearSearch,
} from '../data/actions/filter';
import '../styles/SearchPanel.scss';

library.add(
  faXmark,
);

export default function SearchPanel() {
  const { search } = useSelector((state) => state.appliedFilters);
  const [searchText, setSearch] = useState(search);
  const dispatch = useDispatch();

  // Get new search values
  useEffect(() => {
    setSearch(search);
  }, [search]);

  // Event Handlers
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(dispatchSearch(searchText));
  };

  return (
    <div className="SearchPanel">
      <form
        className="search"
        onSubmit={handleSubmit}
      >
        <input
          ref={(input) => input && input.focus()}
          type="text"
          placeholder="Rezepte suchen"
          value={searchText}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </form>
      <button
        className="clear"
        type="button"
        alt="Suche zurücksetzen"
        onClick={() => {
          setSearch('');
          dispatch(clearSearch());
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
}
