/* eslint-disable no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';

import paths from '../data/paths';
import '../styles/CreateRecipeChip.scss';

library.add(
  faPlus,
);

export default function CreateRecipeChip() {
  return (
    <a
      className="CreateRecipeChip"
      alt="Rezept erstellen"
      href={paths.ADMIN_CREATE_RECIPE}
    >
      <FontAwesomeIcon
        icon={faPlus}
        className="faIcon icon"
      />
    </a>
  );
}
