import { useState, useEffect } from 'react';

export function useWakeLock() {
  const [wakeLockObj, setWakeLockObj] = useState(null);

  useEffect(() => {
    let isMounted = true; // To track if the component is still mounted

    const preventSleep = async () => {
      if ('wakeLock' in navigator) {
        try {
          const wakeLock = await navigator.wakeLock.request('screen');
          if (isMounted) {
            setWakeLockObj(wakeLock);
            console.log('Screen wake lock acquired');
          }
        } catch (error) {
          console.error('Unable to acquire wake lock:', error);
        }
      } else {
        // Fallback for browsers that do not support the wakeLock API
        setInterval(() => {
          // Perform some non-blocking action, like updating the time
          document.getElementById('time').innerText = new Date().toLocaleTimeString();
        }, 1000);
      }
    };

    // Call the preventSleep function to initiate the prevention of screen sleep
    preventSleep();

    return () => {
      // Release the wake lock when the component is unmounted
      isMounted = false;
      if (wakeLockObj !== null) {
        wakeLockObj.release()
          .then(() => {
            console.log('Screen wake lock released');
          })
          .catch((error) => {
            console.error('Error releasing wake lock:', error);
          });
      }
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount
}

export function useWakeUnlock() {
  const enableSleep = async () => {
    if ('wakeLock' in navigator) {
      try {
        const wakeLock = await navigator.wakeLock.request('screen');
        wakeLock.release();
        console.log('wakeLock disabled');
      } catch (error) {
        console.error('Unable to acquire wake lock:', error);
      }
    }
  };
  useEffect(() => {
    enableSleep();
  }, []);
}
