/* eslint-disable no-unused-vars */
import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faPenToSquare,
} from '@fortawesome/pro-regular-svg-icons';

import Chip from './Chip';
import PlaceholderImage from './PlaceholderImage';

import paths, { getEditRecipePath, getMenuPath } from '../data/paths';
import '../styles/RecipeHero.scss';

library.add(
  faArrowLeft,
  faPenToSquare,
);

export default function RecipeHero({
  recipeId,
  recipeName,
  recipeMenu,
  image,
}) {
  return (
    <div className="RecipeHero">
      {
        image
          ? (
            <img
              className="topSection image"
              src={paths.API_ENDPOINT + image.attributes.url}
              alt={recipeName}
            />
          )
          : (
            <PlaceholderImage
              className="topSection"
            />
          )
      }
      <Chip
        className="back"
        icon={faArrowLeft}
        href={getMenuPath(recipeMenu)}
      />
      <Chip
        className="edit"
        icon={faPenToSquare}
        href={getEditRecipePath(recipeId)}
      />
    </div>
  );
}
