/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFilter,
  faSearch,
  faCocktail,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faPotFood,
} from '@fortawesome/pro-solid-svg-icons';

import Chip from './Chip';
import navigateToFilter from '../data/actions/navigate';
import paths, { getMenu } from '../data/paths';

import '../styles/ChipMenu.scss';

library.add(
  faFilter,
  faSearch,
  faCocktail,
  faPotFood,
);

export default function ChipMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Configure based on displayed menu
  const menu = getMenu();
  let menuIcon = null;
  let switchAction = null;
  switch (menu) {
    case paths.MENU_BAR:
      menuIcon = faPotFood;
      switchAction = () => { navigate(`/${paths.MENU_KITCHEN}`); };
      break;
    case paths.MENU_KITCHEN:
      menuIcon = faCocktail;
      switchAction = () => { navigate(`/${paths.MENU_BAR}`); };
      break;
    default:
      menuIcon = null;
  }

  // Event Handlers
  const clickFilter = (e) => {
    dispatch(navigateToFilter('filter'));
  };

  const clickSearch = (e) => {
    dispatch(navigateToFilter('search'));
  };

  const clickSwitchMenu = (e) => {
    if (switchAction) {
      switchAction();
    }
  };

  // Render
  return (
    <div className="ChipMenu">
      <div className="chipList">
        <Chip
          icon={faFilter}
          onClick={clickFilter}
        />
        <Chip
          icon={faSearch}
          onClick={clickSearch}
        />
      </div>
      <div className="chipList">
        <Chip
          icon={menuIcon}
          onClick={clickSwitchMenu}
        />
      </div>
    </div>
  );
}
