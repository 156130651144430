/* eslint-disable no-unused-vars */
import React from 'react';
import '../styles/Ingredient.scss';

export default function Ingredient({
  isTitle,
  quantity,
  unit,
  name,
}) {
  return (
    <div className={`Ingredient ${isTitle ? 'title' : ''}`}>
      <span className="quantity">{`${quantity || ''} ${unit || ''}`}</span>
      <span className="name">{name}</span>
    </div>
  );
}
