/* eslint-disable default-param-last */

const filterReducer = (
  state = {
    search: '',
    categories: [],
    ingredients: [],
    onlyAvailable: false,
    origin: null,
  },
  action,
) => {
  const newState = state;
  switch (action.type) {
    case 'RESET_FILTER': {
      return {
        search: '',
        categories: [],
        ingredients: [],
        onlyAvailable: action.payload.menu === 'bar',
        origin: null,
      };
    }
    case 'TOGGLE_CATEGORY_FILTER': {
      const wasInFilter = newState.categories.some(
        (item) => item.id === action.payload.category.id,
      );

      return {
        ...newState,
        categories: wasInFilter
          ? newState.categories.filter((item) => item.id !== action.payload.category.id)
          : [...newState.categories, action.payload.category],
      };
    }
    case 'TOGGLE_INGREDIENT_FILTER': {
      const wasInFilter = newState.ingredients.some(
        (item) => item.id === action.payload.ingredient.id,
      );

      return {
        ...newState,
        ingredients: wasInFilter
          ? newState.ingredients.filter((item) => item.id !== action.payload.ingredient.id)
          : [...newState.ingredients, action.payload.ingredient],
      };
    }
    case 'SET_ORIGIN_FILTER': {
      return {
        ...newState,
        origin: action.payload.origin,
      };
    }
    case 'SET_SEARCH': {
      return {
        ...newState,
        search: action.payload.search,
      };
    }
    case 'SET_ONLY_AVAILABLE': {
      return {
        ...newState,
        onlyAvailable: action.payload.onlyAvailable,
      };
    }
    default: {
      return state;
    }
  }
};
export default filterReducer;
